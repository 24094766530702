<template>
  <b-container>
    <b-card no-body>
      <div class="p-2 d-flex justify-content-between">
        <div class="d-flex justify-content-start">
          <div class="mr-2">
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodeFrom", "Periode from")
            }}</label>
            <b-form-datepicker
              v-model="fromDate"
              placeholder="YYYY-MM-DD"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              @input="fetchCompletedOrders"
            />
          </div>
          <div>
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodeTo", "Periode to")
            }}</label>
            <b-form-datepicker
              v-model="toDate"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              @input="fetchCompletedOrders"
            />
          </div>
        </div>
        <div>
          <b-button @click="exportData">{{ T("Web.Generic.Export") }}</b-button>
        </div>
      </div>
      <b-table
        small
        responsive
        :fields="tableFields"
        :items="data"
        show-empty
        :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
      >
        <template #cell(date)="data">
          {{ format_date(data.value) }}
        </template>
        <template #cell(orderDate)="data">
          {{ format_date(data.value) }}
        </template>
        <template #cell(orderItem)="data">
          {{ `${data.value} (${data.item.requisitionId})` }}
        </template>
        <template #cell(kgWasteCollected)="data">
          <b-form-input
            type="number"
            v-model="data.item.kgWasteCollected"
            size="sm"
            @blur="updateOrderKgWasteCollected(data.item)"
          />
        </template>
      </b-table>
    </b-card>
  </b-container>
</template>

<script>
import * as XLSX from "xlsx";

import moment from "moment";
import {
  BCard,
  BContainer,
  BFormDatepicker,
  BTable,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
export default {
  components: {
    BFormDatepicker,
    BContainer,
    BCard,
    BTable,
    BButton,
    BFormInput,
  },
  data() {
    return {
      fromDate: moment().startOf("month").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      data: [],
      tableFields: [
        {
          key: "requisitionId",
          label: this.T('Web.Generic.Ordering.RequisitionId'),
          sortable: true,
        },
        {
          key: "date",
          label: this.T('Web.Generic.Date'),
          sortable: true,
        },
        {
          key: "orderDate",
          label: this.T('Web.Generic.Ordering.OrderDate'),
          sortable: true,
        },
        {
          key: "concernName",
          label: this.T('Web.Generic.Concern'),
          sortable: true,
        },
        {
          key: "locationName",
          label: this.T('Web.Generic.Locations.Location'),
          sortable: true,
        },
        {
          key: "orderItem",
          label: this.T('Web.Generic.Infocards.InfocardName'),
          sortable: true,
        },
        {
          key: "wasteFraction",
          label: this.T('Web.Generic.Ordering.WasteFraction'),
          sortable: true,
        },
        {
          key: "ewcCode",
          label: this.T('Web.Generic.Infocards.EWCCode'),
          sortable: true,
        },
        {
          key: "receivingFacilityName",
          label: this.T('Web.Generic.Ordering.RecivingFacility'),
          sortable: true,
        },
        {
          key: "kgWasteCollected",
          label: this.T('Web.Generic.Ordering.KgWasteCollected'),
          sortable: false,
        },
      ],
    };
  },
  async created() {
    await this.fetchCompletedOrders();
  },
  methods: {
    async fetchCompletedOrders() {
      const service = new ResourceService("waste-collection-orders/entries");
      let result = await service.all({
        fromDate: this.fromDate,
        toDate: this.toDate,
      });
      this.data = result.data;
    },
    async updateOrderKgWasteCollected(order) {
      const service = new ResourceService("concern/orders");
      await service.put(`${order.id}/kg-collected`, {
        kgWasteCollected: order.kgWasteCollected,
      });
      this.$bvToast.toast(`Kg opdateret på ${order.requisitionId} `, {
        title: `Opdatering gennemført`,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    exportData() {
      const fileName = `${this.fromDate}_${this.toDate}.xlsx`;
      const modifiedData = this.data.map((item) => {
        return {
          Id: item.requisitionId,
          Dato: this.format_date(item.date),
          Bestillingsdato: this.format_date(item.orderDate),
          Koncern: item.concernName,
          Lokation: item.locationName,
          Infokort: `${item.orderItem} (${item.requisitionId})`,
          Fraktion: item.wasteFraction,
          EAK: item.ewcCode,
          Modtageranlæg: item.receivingFacilityName,
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(modifiedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, fileName);
    },
  },
};
</script>