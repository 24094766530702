var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"p-2 d-flex justify-content-between"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticClass:"mr-2"},[_c('label',{attrs:{"for":"example-datepicker"}},[_vm._v(_vm._s(_vm.T("Web.Generic.PeriodeFrom", "Periode from")))]),_c('b-form-datepicker',{attrs:{"placeholder":"YYYY-MM-DD","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }},on:{"input":_vm.fetchCompletedOrders},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1),_c('div',[_c('label',{attrs:{"for":"example-datepicker"}},[_vm._v(_vm._s(_vm.T("Web.Generic.PeriodeTo", "Periode to")))]),_c('b-form-datepicker',{attrs:{"date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }},on:{"input":_vm.fetchCompletedOrders},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)]),_c('div',[_c('b-button',{on:{"click":_vm.exportData}},[_vm._v(_vm._s(_vm.T("Web.Generic.Export")))])],1)]),_c('b-table',{attrs:{"small":"","responsive":"","fields":_vm.tableFields,"items":_vm.data,"show-empty":"","empty-text":_vm.T('Web.Generic.TableEmpty', 'No items to show')},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.format_date(data.value))+" ")]}},{key:"cell(orderDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.format_date(data.value))+" ")]}},{key:"cell(orderItem)",fn:function(data){return [_vm._v(" "+_vm._s(((data.value) + " (" + (data.item.requisitionId) + ")"))+" ")]}},{key:"cell(kgWasteCollected)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"number","size":"sm"},on:{"blur":function($event){return _vm.updateOrderKgWasteCollected(data.item)}},model:{value:(data.item.kgWasteCollected),callback:function ($$v) {_vm.$set(data.item, "kgWasteCollected", $$v)},expression:"data.item.kgWasteCollected"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }